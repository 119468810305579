<template>
  <div class="container-fluid">
    <div class="story">
      <!-- UM OKKUR -->
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <img src="../assets/hopmynd.jpg" alt="" class="about-img">
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 flexbox"> 
          <h1>{{ $t('about.header')}}</h1>
          <p>{{ $t('about.long1')}}</p>
          <p>{{ $t('about.long2')}}</p>
          <p>{{ $t('about.long3')}}</p>
        </div>
      </div>
      <!-- LOCATION / directions?-->
      <div class="jumbotron text-center">
        <h1>{{ $t('about.location')}}</h1>
        <a class="btn btn-outline-light" href="https://www.google.com/maps/place/Vitatorg,+245+Su%C3%B0urnesjab%C3%A6r/@64.0403572,-22.7147962,17.5z/data=!4m5!3m4!1s0x4929ffb6a36c6b91:0x3f0133c124966ffb!8m2!3d64.0405297!4d-22.7135359"  target="_blank" role="button">
          {{ $t('about.direction')}}
        </a>
      </div>
      <!-- UM STADINN -->
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <img src="../assets/sjavarsetrid.jpg" alt="" class="about-img">
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 flexbox"> 
          <h1>{{ $t('about.header')}}</h1>
          <p>{{ $t('about.long4')}}</p>
          <p>{{ $t('about.long5')}}</p>
        </div>
      </div>
      <!-- OPENING HOURS -->
      <div class="hours">
        <OpeningHours/>
      </div>
    </div>
  </div>
</template>

<script>
import OpeningHours from '../components/OpeningHours.vue'

export default {
  components: {
    OpeningHours
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.story {
  background-color: #262526;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content:  space-evenly;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 100px;
  color: #ffffff;
}
.flexbox h1 {
  margin-bottom: 20px;
}
.flexbox p {
  margin-bottom: 10px;
}
.about-img {
  max-width: 80%;
  margin-top: 50px;
  margin-bottom: 40px;
}
.jumbotron {
  background: url("../assets/lighthouse.jpg");
  background-size: cover;
  background-position: center;
  color: #ffffff;
}
.jumbotron h1 {
  padding-top: 150px;
  padding-bottom: 50px;
}
.jumbotron a {
  margin-bottom: 150px;
  padding: 5px 10px 5px 10px;
}
.hours {
  padding-bottom: 40px;
  margin-top: 50px;
}
@media only screen and (max-width: 991px) {
.flexbox {
  padding-right: 100px;
  padding-left: 100px;
}
.about-img {
  margin-bottom: 0;
  margin-top: 60px;
}
}
@media only screen and (max-width: 767px) {
.flexbox {
  padding-right: 20px;
  padding-left: 20px;
}
.flexbox p {
  font-size: 14px;
}
.about-img {
  margin-top: 80px;
  margin-bottom: 0;
}
}
</style>